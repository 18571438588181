import React from "react"

import Layout from "../components/layout"
import { SEO } from "../components/seo"

const Page = () => {
  return (
    <Layout>
      <SEO
        title={`Fizjoterapia`}
        description={`Fizjoterapia - rozwijanie, utrzymywanie i przywracanie maksymalnej sprawności oraz poprawy funkcjonowania ciała.`}
      />

      <div className="offer-item">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-xs-12">
              <p className="offer-item-description">
                W Balicka Clinic zabiegi wykonuje mgr fizjoterapii Aleksandra
                Balicka
              </p>
            </div>

            <div className="col-md-12 col-xs-12">
              <h1 className="offer-item-title">
                Konsultacja fizjoterapeutyczna
              </h1>
              <div className="separator-line-horizontal"></div>
              <p className="offer-item-description">
                Obejmuje kompleksowe badanie, którego celem jest analiza
                problemu pacjenta. Na podstawie szczegółowego wywiadu z
                pacjentem, specyficznego badania i testowania odnajdujemy źródło
                bólu. Następnie planujemy proces leczenia i fizjoterapii, który
                jest indywidualnie dobierany do pacjenta.
              </p>
            </div>

            <div className="col-md-12 col-xs-12">
              <h2 className="offer-item-title">Drenaż limfatyczny</h2>
              <div className="separator-line-horizontal"></div>
              <p className="offer-item-description">
                Zabieg polegający na ostrożnym stosowaniu technik manualnych w
                celu poprawy wchłaniania i lepszego odprowadzenia płynów
                tkankowych przez chłonkę. Wskazany dla osób borykających się z
                zastojami limfatycznymi, obrzękami pooperacyjnymi bądź
                tłuszczowymi. Zadaniem drenażu jest poprawa krążenia limfy w
                naczyniach limfatycznych.
              </p>
            </div>

            <div className="col-md-12 col-xs-12">
              <h2 className="offer-item-title">Suche igłowanie</h2>
              <div className="separator-line-horizontal"></div>
              <p className="offer-item-description">
                Metoda fizjoterapeutyczna polegająca na nakłuciu punktu
                spustowego w mięśniu lub nakłuciu napiętego pasma mięśniowego za
                pomocą sterylnych, jednorazowych igieł do akupunktury w celu
                leczenia bólu mięśniowo-powięziowego i dysfunkcji w obrębie
                narządu ruchu. Jest wskazane dla osób z przeciążeniami
                mięśniowymi, po urazach sportowych, z zespołami bólowymi.
              </p>
            </div>

            <div className="col-md-12 col-xs-12">
              <h2 className="offer-item-title">Masaż aromaterapeutyczny</h2>
              <div className="separator-line-horizontal"></div>
              <p className="offer-item-description">
                Zabieg fizjoterapeutyczny o charakterze leczniczym i
                relaksacyjnym, połączony z wyjątkowym działaniem aromatycznych
                olejków eterycznych. Masaż ten jest szczególnie polecany osobom
                żyjącym w ciągłym stresie. Wykonywany spokojnymi i rytmicznymi
                ruchami niweluje stres, a zarazem wspaniale oddziałuje na układ
                nerwowy - likwiduje napięcie mięśni, przywraca prawidłowy rytm
                serca, właściwe krążenie i ciśnienie krwi, a nawet pomaga w
                walce z bezsennością oraz z ogólnym wycieńczeniem organizmu..
              </p>
            </div>

            <div className="col-md-12 col-xs-12">
              <h2 className="offer-item-title">Masaż całego ciała</h2>
              <div className="separator-line-horizontal"></div>
              <p className="offer-item-description">
                Polega na opracowaniu tylnej i przedniej taśmy mięśniowej
                pacjenta. Jego celem jest zmniejszenie napięcia mięśniowego,
                redukcja stresu, poprawa ukrwienia, niwelowanie stresu oraz
                udoskonalenie samopoczucia. Trwa do 90 minut i zawiera w sobie
                techniki masażu klasycznego, tkanek głębokich oraz
                relaksacyjnego.
              </p>
            </div>

            <div className="col-md-12 col-xs-12">
              <h2 className="offer-item-title">Masaż gorącym olejkiem</h2>
              <div className="separator-line-horizontal"></div>
              <p className="offer-item-description">
                Zabieg rozgrzewający, regenerujący i nawilżający skórę. Gorący
                olejek, dzięki cennym witaminom i minerałom dogłębnie odżywia,
                rozluźnia napięte mięśnie, niweluje ból oraz harmonizuje umysł i
                ciało, wprowadzając osobę masowaną w stan głębokiego relaksu.
              </p>
            </div>

            <div className="col-md-12 col-xs-12">
              <h2 className="offer-item-title">Masaż klasyczny twarzy</h2>
              <div className="separator-line-horizontal"></div>
              <p className="offer-item-description">
                Efektem regularności stosowania jest poprawa stanu naskórka,
                wyrównanie kolorytu, a także dotlenienie i odżywienie skóry.
                Zalecany jest także osobom cierpiącym na występowanie opuchlizny
                na twarzy, podkrążone oczy, cienie pod oczami, bóle głowy. W
                jego trakcie opracowane są również struktury mięśniowe szyi,
                klatki piersiowej i barków.
              </p>
            </div>

            <div className="col-md-12 col-xs-12">
              <h2 className="offer-item-title">Masaż masłem shea</h2>
              <div className="separator-line-horizontal"></div>
              <p className="offer-item-description">
                To masaż relaksacyjny, podczas którego terapeuta stosuje płynne
                ruchy, by wmasować masło shea w ciało. Przed zabiegiem produkt
                jest delikatnie podgrzewane do temperatury ok. 40°c, co zwiększa
                relaksujący i terapeutyczny wpływ masażu. Łagodzi podrażnienia,
                zmniejsza rozstępy i blizny. Odżywia skórę i czyni ją
                piękniejszą, gładszą, bardziej elastyczną, znakomicie rozluźnia
                mięśnie i wprowadza w stan pełnego odprężenia. Pozwala
                całkowicie zapomnieć o stresie i zmęczeniu!
              </p>
            </div>

            <div className="col-md-12 col-xs-12">
              <h2 className="offer-item-title">Masaż relaksacyjny</h2>
              <div className="separator-line-horizontal"></div>
              <p className="offer-item-description">
                Ma na celu pobudzenie organizmu do zwiększonego wydzielania
                endorfin, co skutkuje natychmiastową poprawą samopoczucia. To
                technika, która ma na celu nie tylko przyspieszenie przepływu
                krwi i limfy, ale również wywołanie u pacjenta błogostanu i
                całkowitego odprężenia.
              </p>
            </div>

            <div className="col-md-12 col-xs-12">
              <h2 className="offer-item-title">Masaż sportowy</h2>
              <div className="separator-line-horizontal"></div>
              <p className="offer-item-description">
                W masażu sportowym stosuje się podobne techniki do
                wykorzystywanych w masażu klasycznym. Różnica polega na tym, że
                w masażu sportowym pracuje się na określonym mięśniu lub grupie
                mięśniowej i używa się dużo większej siły nacisku niż w
                przypadku masażu klasycznego. Ten rodzaj masażu przyśpiesza
                regenerację mięśni, ścięgien i więzadeł, zmniejsza napięcie
                mięśniowe oraz zwiększa zakres ruchu, minimalizując ryzyko
                doznania kontuzji i zwiększając wydajność sportowca.
              </p>
            </div>

            <div className="col-md-12 col-xs-12">
              <h2 className="offer-item-title">Masaż gorącą świecą-</h2>
              <div className="separator-line-horizontal"></div>
              <p className="offer-item-description">
                Podczas masażu terapeuta zapala specjalną świecę i wylewa na
                ciało odpowiednią ilość płynnego wosku o jedwabistej
                konsystencji. Rozpuszczony wosk ma bardzo przyjemną temperaturę,
                nie zasycha na skórze, lecz tworzy na niej aksamitną powłokę jak
                olej do masażu. Wpływa na rozluźnienie mięśniowe, zrelaksowanie,
                odżywienie skóry.
              </p>
            </div>

            <div className="col-md-12 col-xs-12">
              <h2 className="offer-item-title">Terapia pourazowa</h2>
              <div className="separator-line-horizontal"></div>
              <p className="offer-item-description">
                Wizyta fizjoterapeutyczna przeznaczona dla osób, które mają
                dolegliwości bólowe wynikające z niedawno przebytego urazu bądź
                znajdują się w okresie pooperacyjnym. W zależności od rodzaju
                urazu czy rozległości stanu pooperacyjnego terapeuta dopiero
                odpowiednie techniki fizjoterapeutyczne i planuje proces
                rehabilitacji, który zniweluje doskwierający ból i pozwoli
                pacjentowi powrócić do pełni zdrowia.
              </p>
            </div>

            <div className="col-md-12 col-xs-12">
              <h2 className="offer-item-title">Terapia bólów głowy</h2>
              <div className="separator-line-horizontal"></div>
              <p className="offer-item-description">
                Manualna praca fizjoterapeuty, która polega na opracowaniu
                napiętych struktur twarzoczaszki, szyi, karku, klatki piersiowej
                bądź kręgosłupa piersiowego. Techniki dobierane są indywidualnie
                do pacjenta w zależności od częstotliwości i natężenia bólów
                głowy oraz występujących objawów współtowarzyszących. Na taką
                terapię składa się szereg metod mających na celu redukcję
                wzmożonego napięcia mięśniowego, lecz także udrożnienie układu
                żylnego, dotlenienie głowy, mobilizacja wyrostków poprzecznych
                odcinka szyjnego, opracowanie kątów żylnych.
              </p>
            </div>

            <div className="col-md-12 col-xs-12">
              <h2 className="offer-item-title">
                Terapia narzędziowa/pinoterapia
              </h2>
              <div className="separator-line-horizontal"></div>
              <p className="offer-item-description">
                Techniki fizjoterapeutyczne, wykorzystywane w trakcie terapii
                leczniczej. Piny, katy, miodowanie, masażer „gun”, bańka chińska
                to jedne z narzędzi wykorzystywanych w trakcie terapii, w
                zależności od stanu chorobowego pacjenta i przybranej formy
                leczenia fizjoterapeutycznego.
              </p>
            </div>

            <div className="col-md-12 col-xs-12">
              <h2 className="offer-item-title">Terapia relaksacyjna</h2>
              <div className="separator-line-horizontal"></div>
              <p className="offer-item-description">
                Wykorzystuje metody pinoterapii, mobilizacji tkanek miękkich,
                masażu tkanek głębokich, techniki oscylacyjne, które wykonywane
                są w obrębie głowy, szyi , klatki piersiowej i twarzoczaszki -
                czyli okolic najbardziej narażonych na napięcie u osób żyjących
                w stałym stresie. Przeznaczona dla osób chcących się zrelaksować
                świadomie, gwarantująca efekt rozluźnienia na dłuższy okres
                czasu w porównaniu do efektu który daje klasyczna forma masażu
                relaksacyjnego.
              </p>
            </div>

            <div className="col-md-12 col-xs-12">
              <h2 className="offer-item-title">
                Terapia stawów skroniowo-żuchwowych
              </h2>
              <div className="separator-line-horizontal"></div>
              <p className="offer-item-description">
                Wskazana dla osób z bruksizmem, wypadaniem stawu, zaciskaniem
                szczęki, ścieraniem zębów , trzaskami i strzelaniem w stawie
                skroniowo-żuchowym oraz bólem w czasie szerokiego otwierania
                jamy ustnej.
              </p>
            </div>

            <div className="col-md-12 col-xs-12">
              <h2 className="offer-item-title">Terapia strukturalna</h2>
              <div className="separator-line-horizontal"></div>
              <p className="offer-item-description">
                Indywidualnie dobrane techniki fizjoterapeutyczne, które
                dostosowane są do pacjenta w zależności od jego stanu zdrowia.
                Podczas niej wykorzystuje się kilka technik pochodzących z
                różnych źródeł, a każda z nich ma inny cel, co w całości ma
                gwarantować ogólne usprawnienie pacjenta.
              </p>
            </div>

            <div className="col-md-12 col-xs-12">
              <h2 className="offer-item-title">Terapia wisceralna</h2>
              <div className="separator-line-horizontal"></div>
              <p className="offer-item-description">
                Praca fizjoterapeuty z narządami wewnętrznymi pacjenta
                mieszczącymi się w obrębie jamy brzusznej. Zalecana dla osób ze
                wzdęciami brzucha, bólem żołądka/ wątroby, problemami z
                jelitami, częstymi zaparciami/biegunkami, uczuciem bulgotania,
                refluksem. Terapia ma na celu usprawnienie działania narządów
                wewnętrznych, rozluźnienie przepony, poprawę metabolizmu i
                zniwelowanie uczucia dyskomfortu i odczuwanego bólu.
              </p>
            </div>

            <div className="col-md-12 col-xs-12">
              <h2 className="offer-item-title">Terapia zatok</h2>
              <div className="separator-line-horizontal"></div>
              <p className="offer-item-description">
                Wykorzystuje techniki fizjoterapeutyczne, których celem jest
                udrożnienie zalegającej wydzieliny w zatokach. Usuwa gęsty i
                ropny śluz z wnętrza zatok, pozwala pozbyć się uczucia zatkanego
                nosa, usprawnia oddech, zmniejsza obrzęki twarzoczaszki oraz
                tkliwość gałek ocznych.
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Page
